import gsap from "gsap"

import {ScrollTrigger} from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

export const animateWithGsap = (target,animationProps,scrollProps)=>{
    gsap.to(target,
        {
            ...animationProps,
            scrollTrigger:{
                trigger:target,
                toggleActions:'restart reverse restart reverse',
                start:'top 85%',
                ...scrollProps
            }
        })
}

export const animateWithGsapTimeline = (timeline,rotationRef,rotationState,firstTarget,secondTarget,animationProps)=>{
    timeline.to(rotationRef.current.rotation,{
        y:rotationState,
        duration:1,
        ease:'power2.inOut'
    })
    
    timeline.to(firstTarget,{
        ...animationProps,ease:'power2.inOut'
    },
    // The symbol below lets gsap animate the second item of the timeline at the start of the first timeline animation
    '<')

    timeline.to(secondTarget,{
        ...animationProps,ease:'power2.inOut'
    },
    // The symbol below lets gsap animate the second item of the timeline at the start of the first timeline animation
    '<')
}